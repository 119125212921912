import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, useNavigate } from '@gatsbyjs/reach-router';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridColTypeDef,
  GridCellParams,
  getGridStringOperators,
} from '@material-ui/data-grid';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useQuery, useLazyQuery, useMutation, Reference } from '@apollo/client';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import {
  Invoices_invoices_rows as Invoice,
  Invoices as InvoicesQuery,
  Invoices_invoices_summary as Summary,
  InvoicesVariables,
} from '../graph-types/Invoices';

import { ArchiveInvoices, ArchiveInvoicesVariables } from '../graph-types/ArchiveInvoices';
import { DownloadInvoices, DownloadInvoicesVariables } from '../graph-types/DownloadInvoices';
import { GET_INVOICES, ARCHIVE_INVOICES, DOWNLOAD_INVOICES, GET_SUMMARIES } from '../queries/invoices';
import { getCaseName } from '../data/case';
import { InvoiceDialog } from './invoice-dialog';
import { getDateInUTC } from '../utils/dates';
import { downloadContent } from '../utils/downloads';
import { GetSummaries, GetSummariesVariables } from '../graph-types/GetSummaries';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const spDate: GridColTypeDef = {
  type: 'string',
  valueFormatter: ({ value }) => (value ? new Intl.DateTimeFormat('es-ES').format(getDateInUTC(value as string)) : ''),
};

const withTooltip: GridColTypeDef = {
  renderCell: (params: GridCellParams) => {
    return (
      <Tooltip interactive title={<Typography variant="subtitle1">{params.value || ''}</Typography>}>
        <Typography noWrap>{params.value}</Typography>
      </Tooltip>
    );
  },
};

const stopPropagation = (event: React.SyntheticEvent) => event.stopPropagation();
const launcher: GridColTypeDef = {
  renderCell: ({ value }) =>
    value ? (
      <Link
        onClick={stopPropagation}
        href={`https://app.qbo.intuit.com/app/timeactivity?t=s&id=${value}`}
        target="__blank"
      >
        <LaunchIcon />
      </Link>
    ) : (
      <></>
    ),
};

const bool: GridColTypeDef = {
  valueFormatter: ({ value }) => (value ? 'Sí' : 'No'),
};

const getCurrency = (money: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money);
};

const getNumberFormatted = (result: number): string => {
  return new Intl.NumberFormat('en-US').format(result);
};

const useStyles = makeStyles((theme) =>
  createStyles({
    archiveFilter: {
      marginBottom: 0,
    },
    topCard: {
      height: theme.spacing(5),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    grid: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  })
);

type Props = RouteComponentProps & {
  isAdmin: boolean;
};

export const Invoices: React.FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState<{ status: 'success' | 'error' | 'closed'; msg: string }>({
    status: 'closed',
    msg: '',
  });

  const [activeInvoice, setActiveInvoice] = useState<Invoice | null>(null);
  const [isArchived, setIsArchived] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const selectedInvoicesRef = useRef<Set<string>>(new Set());
  const [summary, setSummary] = useState<Omit<Summary, '__typename'>>({ gastos: 0, horas: 0, total: 0 });
  const [summaryYear, setSummaryYear] = useState<'2018' | '2019' | '2020' | '2021' | '2022' | '2023' | '2024' | '2025'>('2025');

  const [filter, setFilter] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [total, setTotal] = useState<number>(0);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [hasReachedLastPage, setReachedLastPage] = useState<boolean>(false);

  // Each page will correspond to the cursor it needed to load it
  // For example, page 0, first page, doesnt use any cursor so its null
  // Page 1, will have cursor provided from page 0, and so forth
  // Last page will have a null cursor but wont be stored since paging will be done
  const pageStateRef = useRef<{ [page: number]: string | null }>({ 0: null });

  const currentCursor = pageStateRef.current?.[page] ?? null;
  const rowCount = isDone ? total : Number.MAX_VALUE;

  const { loading, error, data, refetch } = useQuery<InvoicesQuery, InvoicesVariables>(GET_INVOICES, {
    variables: {
      archived: isArchived,
      monthYear: selectedDate?.getTime().toString() ?? null,
      pageSize,
      cursor: currentCursor,
      caseName: filter,
    },
    onCompleted: (data) => {
      setSummary(data.invoices?.summary ?? summary);

      if (filter) {
        const total = data.invoices?.rows.length ?? 0;
        const hasOnePage = page === 0 && total <= pageSize;
        const reachedLastPage = hasOnePage || page === Math.ceil(total / pageSize);

        setReachedLastPage(reachedLastPage);
        setTotal(total);

        if (!isDone) {
          setIsDone(reachedLastPage);
        }
      } else {
        const cursor = data.invoices?.cursor;
        const hasOnePage = page === 0 && cursor == null;
        const reachedLastPage = hasOnePage || (page > 0 && cursor == null);

        setReachedLastPage(reachedLastPage);

        if (!isDone) {
          setIsDone(reachedLastPage);
          setTotal(total + (data.invoices?.rows?.length ?? 0));
        }
      }
    },
  });

  const summariesQuery = useQuery<GetSummaries, GetSummariesVariables>(GET_SUMMARIES, {
    variables: { year: summaryYear },
  });

  const [archive, archiveStatus] = useMutation<ArchiveInvoices, ArchiveInvoicesVariables>(ARCHIVE_INVOICES, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          invoices(existingCorrs = [], { readField }) {
            if (!data) {
              return existingCorrs;
            }

            return existingCorrs.filter(
              (invRef: Reference) => !data.archiveInvoices.includes(readField('id', invRef) ?? '')
            );
          },
        },
      });
    },
  });

  const [downloadInvoices] = useLazyQuery<DownloadInvoices, DownloadInvoicesVariables>(DOWNLOAD_INVOICES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      downloadContent(data.downloadInvoices, 'invoices');
    },
  });

  useEffect(() => {
    // Reset when switching filter
    pageStateRef.current = { 0: null };
    setSummary({ gastos: 0, horas: 0, total: 0 });
    setIsDone(false);
    setPage(0);
    setTotal(0);
    // Fetch
    refetch();
  }, [refetch, isArchived, pageSize, selectedDate, filter]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (msg: string | null, keepOpen: boolean) => {
    setActiveInvoice(null);
    setOpen(keepOpen);

    if (msg) {
      setNotification({ status: 'success', msg });
    }
  };

  const handleSnackbarClose = () => {
    setNotification({ status: 'closed', msg: notification.msg });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsArchived(event.target.checked);
  };

  const rows: GridRowsProp =
    data?.invoices?.rows.map((inv) => ({
      id: inv.id,
      col1: inv.dateOfEvent,
      col2: inv.case ? getCaseName(inv.case.client.name, inv.case.group?.name || null, inv.case.matter) : '',
      col3: inv.unit,
      col4: inv.flatFee.name,
      col5: inv.note,
      col6: inv.isExported,
      col7: inv.qbId,
      col8: inv.qbUpdate,
    })) ?? [];

  const noteWidth = matches ? { flex: 1 } : { width: 500 };

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Fecha de Evento', width: 140, ...spDate, filterable: false },
    {
      field: 'col2',
      headerName: 'Caso Asociado',
      width: 300,
      ...withTooltip,
      filterable: true,

      filterOperators: [getGridStringOperators()[0]],
    },
    { field: 'col3', headerName: 'Tiempo', width: 100, filterable: false },
    { field: 'col4', headerName: 'Item', width: 125, filterable: false },
    { field: 'col5', headerName: 'Nota', ...noteWidth, ...withTooltip, filterable: false },
    { field: 'col6', headerName: 'Archivado', width: 110, ...bool, filterable: false },
    { field: 'col7', headerName: 'QBO', width: 100, ...launcher, filterable: false },
    { field: 'col8', headerName: 'Actualizado QBO', width: 140, ...spDate, filterable: false },
  ];

  const invoices = data?.invoices?.rows ?? [];

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <Typography variant="h3" gutterBottom>
          Facturación
        </Typography>
        {props.isAdmin && (
          <Grid container spacing={2}>
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.topCard }}>
                  <Typography variant="h5">
                    {'Total horas: '}
                    {getNumberFormatted(summary.horas)}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.topCard }}>
                  <Typography variant="h5">
                    {'Total gastos: '}
                    {getCurrency(summary.gastos)}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.topCard }}>
                  <Typography variant="h5">
                    {'Total: '}
                    {getCurrency(summary.total)}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Ver Año</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">Año</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={summaryYear}
                            onChange={(event) => setSummaryYear(event.target.value as any)}
                          >
                            <MenuItem value={'2018'}>2018</MenuItem>
                            <MenuItem value={'2019'}>2019</MenuItem>
                            <MenuItem value={'2020'}>2020</MenuItem>
                            <MenuItem value={'2021'}>2021</MenuItem>
                            <MenuItem value={'2022'}>2022</MenuItem>
                            <MenuItem value={'2023'}>2023</MenuItem>
                            <MenuItem value={'2024'}>2024</MenuItem>
                            <MenuItem value={'2025'}>2025</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <ResponsiveContainer width="100%" height={400}>
                          <LineChart
                            width={500}
                            height={300}
                            data={!summariesQuery.loading ? summariesQuery.data?.summaries || [] : []}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="month"
                              tickFormatter={(tick) => {
                                return new Date(2022, tick, 1).toLocaleString('es', { month: 'long' });
                              }}
                            />
                            <YAxis
                              yAxisId="left"
                              tickFormatter={(tick) => {
                                return getCurrency(tick);
                              }}
                            />
                            <YAxis yAxisId="right" orientation="right" />
                            <RTooltip
                              labelFormatter={(label) => {
                                return new Date(2022, label, 1).toLocaleString('es', { month: 'long' });
                              }}
                              formatter={(value, name) => {
                                switch (name) {
                                  case 'horas':
                                    return [getNumberFormatted(value as number), 'Total Horas'];
                                  case 'total':
                                    return [getCurrency(value as number), 'Total'];
                                  case 'gastos':
                                    return [getCurrency(value as number), 'Total Gastos'];
                                  default:
                                    return value;
                                }
                              }}
                            />
                            <Legend />
                            <Line yAxisId="left" type="monotone" dataKey="gastos" stroke="#8884d8" />
                            <Line yAxisId="left" type="monotone" dataKey="total" stroke="#835444" />
                            <Line yAxisId="right" type="monotone" dataKey="horas" stroke="#82ca9d" />
                          </LineChart>
                        </ResponsiveContainer>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          </Grid>
        )}
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" disableElevation onClick={handleClickOpen}>
              Crear
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={isArchived}
              onClick={() =>
                archive({
                  variables: {
                    ids: Array.from(selectedInvoicesRef.current),
                  },
                })
              }
            >
              Archivar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() =>
                downloadInvoices({
                  variables: {
                    ids: Array.from(selectedInvoicesRef.current),
                  },
                })
              }
            >
              Exportar
            </Button>
          </Grid>
          <Grid item>
            <FormGroup row>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                label="Filtrar por Mes"
                inputVariant="outlined"
                format="MM/yyyy"
                InputLabelProps={{ shrink: true }}
                onChange={(value) => {
                  setSelectedDate(value);
                }}
                invalidDateMessage="Formato de fecha incorrecto"
                value={selectedDate}
                views={['month', 'year']}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                classes={{ root: classes.archiveFilter }}
                control={
                  <Switch name="isArchived" color="primary" checked={isArchived} onChange={handleSwitchChange} />
                }
                label="Archivado?"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <DataGrid
          className={classes.grid}
          componentsProps={
            filter
              ? {}
              : {
                  pagination: {
                    backIconButtonProps: {
                      disabled: loading || page === 0,
                    },
                    nextIconButtonProps: {
                      disabled: loading || hasReachedLastPage,
                    },
                    labelDisplayedRows: (display: { from: number; to: number; count: number }) =>
                      `${display.from}-${display.to} de ${
                        display.count === Number.MAX_VALUE ? 'muchos' : String(display.count)
                      } `,
                  },
                }
          }
          rows={rows}
          columns={columns}
          loading={loading}
          error={error}
          pageSize={50}
          page={page}
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={filter ? total : rowCount}
          paginationMode={filter ? 'client' : 'server'}
          filterMode="server"
          checkboxSelection
          autoHeight
          disableSelectionOnClick
          onPageChange={(newPage) => {
            if (!pageStateRef.current[newPage.page]) {
              pageStateRef.current[newPage.page] = data?.invoices?.cursor ?? null;
            }

            if (!loading) {
              setPage(newPage.page);
            }
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
          onFilterModelChange={({ filterModel }) => {
            setFilter(filterModel.items[0].value ?? null);
          }}
          onCellClick={(params, e) => {
            if (params.colIndex === 2) {
              const inv = invoices.find((inv) => inv.id === params.row.id);
              if (inv) {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/cases/${inv.case.id}`);
              }
            }
          }}
          onSelectionModelChange={(params) => {
            selectedInvoicesRef.current = new Set(params.selectionModel.map((id) => String(id)));
          }}
          onRowSelected={(params) => {
            if (params.isSelected) {
              selectedInvoicesRef.current.add(String(params.data.id));
            } else {
              selectedInvoicesRef.current.delete(String(params.data.id));
            }
          }}
          onRowClick={(params): void => {
            const inv = invoices.find((inv) => inv.id === params.row.id);
            if (inv) {
              setActiveInvoice(inv);
              setOpen(true);
            }
          }}
        />

        <InvoiceDialog caso={null} open={open} handleClose={handleClose} invoice={activeInvoice} />

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={notification.status === 'success'}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="success">
            {notification.msg}
          </MuiAlert>
        </Snackbar>
        <Backdrop open={archiveStatus.loading} />
      </MuiPickersUtilsProvider>
    </>
  );
};
